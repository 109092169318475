import styled from "styled-components";

import Background from "../../assets/landing_pages/background.jpg";
import BackgroundMobile from "../../assets/landing_pages/background_mobile.svg";

export const Container = styled.main`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(${Background});
  background-repeat: no-repeat;
  background-size: cover;
  background-position-x: center;

  @media (max-width: 475px) {
    background-image: url(${BackgroundMobile});
    background-repeat: no-repeat;
    height: 100%;
  }
`;

export const Content = styled.section`
  width: 100%;
  max-width: 745px;
  padding: 10px 10px;

  @media (max-width: 475px) {
    align-items: center;
    padding: 10px 10px;
    height: 100%;
    margin-top: 15px;
  }
`;

export const ContainerNameEvent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 38px;
`;

export const LogoEvent = styled.img`
  width: 250px;
  height: 80px;
  margin-bottom: 38px;
`;

export const DescriptionEvent = styled.div`
  width: 100%;
  font-family: "GothamPro", sans-serif;
  font-size: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 57px;

  div {
    width: 100%;

    h1 {
      font-size: 50px;
      font-family: "GothamPro", sans-serif;

      @media (max-width: 650px) {
        font-size: 20px;
      }
    }

    h2 {
      font-size: 20px;
      font-weight: normal;

      @media (max-width: 650px) {
        font-size: 14px;
      }
    }
  }

  @media (max-width: 650px) {
    flex-direction: column;
    margin-bottom: 35px;
  }
`;

export const Heading2 = styled.h1`
  font-size: 50px;
  font-family: "GothamPro", sans-serif;

  @media (max-width: 650px) {
    font-size: 30px;
  }
`;

export const RacketImage = styled.img`
  width: 145px;
  height: 84px;
`;

export const Informations = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;
`;

export const Text = styled.span`
  font-family: "GothamPro", sans-serif;
  font-size: 18px;
  font-weight: bold;
`;

export const TextOne = styled(Text)``;
export const TextTwo = styled(Text)``;

export const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  padding: 20px;
  border-radius: 5px;
`;

export const ContainerButton = styled.div`
  max-width: 300px;
  width: 100%;
  margin-top: 10px;
`;
