import styled from "styled-components";

import Background from "../../assets/landing_pages/background.jpg";
import BackgroundMobile from "../../assets/landing_pages/background_mobile.svg";

export const Container = styled.main`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(${Background});
  background-repeat: no-repeat;
  background-size: cover;
  background-position-x: center;
  padding: 10px 10px;

  @media (max-width: 475px) {
    background-image: url(${BackgroundMobile});
    background-repeat: no-repeat;
    height: 100%;
  }
`;

export const ContainerInformations = styled.section`
  width: 100%;
  max-width: 745px;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const Form = styled.form`
  width: 100%;
  display: flex;
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  justify-content: center;
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  border-radius: 5px;
  flex-direction: column;
  align-items: center;
`;

export const Informations = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ContentTitle = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 20px;
  justify-content: space-between;

  @media (max-width: 650px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const LogoTennisClinic = styled.img`
  width: 177px;
  height: 91px;
  margin-bottom: 20px;
`;

export const Racket = styled.img`
  width: 145px;
  height: 84px;
`;

export const TextClinic = styled.label`
  font-size: 20px;
  font-weight: 400;
  font-family: "GothamPro", sans-serif;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const ContentInfos = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
`;

export const TextInfo = styled.label`
  font-size: 17px;
  font-family: "GothamPro", sans-serif;
`;

export const TextInfoTwo = styled.label`
  font-weight: 600;
  font-size: 19px;
  font-family: "GothamPro", sans-serif;
`;

export const ContainerButton = styled.div`
  max-width: 300px;
  width: 100%;
  margin-top: 10px;
`;
