import { Controller, useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";

import LoadingForm from "../../components/loadingForm";
import MaskedTextField from "../../components/maskedField";
import Button from "../../components/button";
import ContainerDisabled from "../../components/containerDisabled";

import Logo from "../../assets/landing_pages/logo.svg";
import Racket from "../../assets/landing_pages/racket_beach_tennis.svg";

import { TextField } from "@mui/material";
import {
  Container,
  ContainerButton,
  ContainerNameEvent,
  Content,
  DescriptionEvent,
  Form,
  Heading1,
  Heading2,
  Informations,
  LogoEvent,
  RacketImage,
  TextOne,
  TextThree,
  TextTwo,
} from "./styles";

const BeachTennisFemale = () => {
  const { control, handleSubmit, register } = useForm();

  const [loading, setLoading] = useState(false);
  const [available, setAvailabe] = useState(false);

  const checkLength = (value, length) => {
    return value.replace(/[^0-9]/g, "").length === length;
  };

  const checkSlots = async () => {
    setLoading(true);

    try {
      const response = await fetch("https://api.caiobasc.com.br/check-slots", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          eventName: "Beach Tennis Feminino",
        }),
      });

      const data = await response.json();

      if (data.registration_closed === false) {
        setAvailabe(true);
      }
    } catch (error) {
      console.log("Erro:", error);
    } finally {
      setLoading(false);
    }
  };

  const onSubmit = async (formData) => {
    setLoading(true);

    try {
      const response = await fetch(
        "https://api.caiobasc.com.br/landing-page/register",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            ...formData,
            eventName: "Beach Tennis Feminino",
          }),
        }
      );

      const data = await response.json();

      if (data.success === false) {
        setLoading(false);
        Swal.fire({
          title: "Erro ao enviar formulário",
          html: data.message,
          icon: "error",
        }).then(() => {
          window.location.reload();
        });
        return;
      }

      setLoading(false);

      Swal.fire({
        title: "Formulário enviado com sucesso!",
        icon: "success",
      }).then(() => {
        window.location.reload();
      });
    } catch (error) {
      setLoading(false);
      Swal.fire({
        title: `Erro ao enviar formulário: ${error}`,
        html: "Não foi possível enviar o formulário",
        icon: "error",
      }).then(() => {
        window.location.reload();
      });
    }
  };

  useEffect(() => {
    checkSlots();
  }, []);

  return (
    <Container>
      {loading && <LoadingForm />}

      <Content>
        <ContainerNameEvent>
          <LogoEvent alt="logo" src={Logo} />
          <DescriptionEvent>
            <div>
              <h2>Inscrição para o</h2>
              <Heading1>TORNEIO DE BEACH TENNIS</Heading1>
              <Heading2>RAINHA DA PRAIA</Heading2>
              <Heading1>by C6 Bank</Heading1>
            </div>
            <RacketImage alt="raquete" src={Racket} />
          </DescriptionEvent>

          <Informations>
            <TextOne>VEM DE BEACH!</TextOne>
            <TextTwo>NÃO ESQUEÇAM DE LEVAR SUAS PRÓPRIAS RAQUETES!</TextTwo>
            <TextThree>
              Caso não consiga participar por algum motivo, avise a organização
              do evento!
            </TextThree>
            <TextThree style={{ fontSize: 20 }}>
              Importante: atividade destinada para mães!
            </TextThree>
          </Informations>
        </ContainerNameEvent>
        <Form onSubmit={handleSubmit(onSubmit)}>
          {available === false ? (
            <ContainerDisabled />
          ) : (
            <>
              <TextField
                {...register("name")}
                required
                label="Nome da Jogadora"
                variant="standard"
                fullWidth
                sx={{ mb: 2 }}
              />

              <TextField
                {...register("email")}
                label="Email"
                required
                variant="standard"
                fullWidth
                sx={{ mb: 2 }}
              />

              <Controller
                control={control}
                name="phone"
                rules={{
                  validate: (value) =>
                    checkLength(value, 11) || "Telefone incompleto",
                }}
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => (
                  <MaskedTextField
                    mask="(99) 99999-9999"
                    label="Telefone"
                    value={value}
                    variant="standard"
                    onChange={onChange}
                    fullWidth
                    sx={{ mb: 2 }}
                    error={!!error}
                    helperText={error ? error.message : null}
                  />
                )}
              />

              <ContainerButton>
                <Button backgroundColor="#232B60" type="submit" width="100%">
                  Confirmar
                </Button>
              </ContainerButton>
            </>
          )}
        </Form>
      </Content>
    </Container>
  );
};

export default BeachTennisFemale;
