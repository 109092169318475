import Logo from "../../assets/logo.webp";
import WhatsappIcon from "../../assets/logo_whatsapp.png";

import { Container, LogoCaioba, LogoWhatsapp, Message } from "./styles";

const Maintenance = () => {
  return (
    <Container>
      <LogoCaioba src={Logo} alt="logo" />

      <Message>
        Fique de olho, em breve novidades sobre nosso próximo encontro!
      </Message>

      <Message>
        Quer falar com o Caioba? Nós estamos com um novo número. Clique no link
        abaixo e entre em contato.
      </Message>

      <LogoWhatsapp
        src={WhatsappIcon}
        alt="logo"
        onClick={() => window.open("https://wa.me/5511915535389")}
      />
    </Container>
  );
};

export default Maintenance;
