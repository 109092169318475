import styled from "styled-components";

export const Container = styled.main`
  width: 100%;
  height: 100vh;
  background-color: #151515;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 30px;
  padding: 0 20px;
`;

export const LogoCaioba = styled.img`
  width: 120px;
`;

export const Message = styled.span`
  font-size: 30px;
  color: white;
  font-family: "GothamPro", sans-serif;
  max-width: 700px;
  width: 100%;
  text-align: center;

  @media (max-width: 425px) {
    font-size: 20px;
  }
`;

export const LogoWhatsapp = styled.img`
  width: 100px;
  height: 100px;
  cursor: pointer;
`;
