import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Maintenance from "./maintenance";
import BeachTennisMale from "./beachTennisMale";
import BeachTennisFemale from "./beachTennisFemale";
import TennisClinic from "./tennisClinic";
import MothersGame from "./mother'sGame";
import FathersGame from "./father'sGame";

const Root = () => {
  return (
    <Router>
      <Routes>
        <Route path="*" element={<Maintenance />} />
        <Route path="/beach-tennis-feminino" element={<BeachTennisFemale />} />
        <Route path="/beach-tennis-masculino" element={<BeachTennisMale />} />
        <Route path="/clinica-de-tenis" element={<TennisClinic />} />
        <Route path="/jogo-das-maes" element={<MothersGame />} />
        <Route path="/jogo-dos-pais" element={<FathersGame />} />
      </Routes>
    </Router>
  );
};

export default Root;
